import { generateId } from '@allibee/components';
import { cloneDeep } from 'lodash-es';
import { setContext } from 'svelte';
import { getContext } from 'svelte';
import { derived, writable } from 'svelte/store';
import type { TemplateFieldConfig, TemplateFieldConfigMap } from '../../type';

export type FieldFormValue<FieldType extends TemplateFieldConfig['type'] = TemplateFieldConfig['type']> = Omit<TemplateFieldConfigMap<FieldType>, 'id'> & {
    type: FieldType;
    referenceType: 'inline' | 'block';
};

export const createTemplateFieldConfigFormContext = <T extends TemplateFieldConfig['type']>(initialFormValues: FieldFormValue<T>) => {
    const values = writable<FieldFormValue<T>>(initialFormValues);
    const errors = writable<Partial<Record<keyof FieldFormValue<T>, string>>>({});

    return {
        values,
        errors,
        isValid: derived(errors, $errors => Object.values($errors).every(error => !error)),
    };
};

type TemplateFieldConfigFormContext<T extends TemplateFieldConfig['type']> = ReturnType<typeof createTemplateFieldConfigFormContext<T>>;

const baseFieldInitialFormValues: FieldFormValue = {
    name: '',
    type: 'text',
    description: '',
    required: true,
    comment: '',
    editable: true,
    referenceType: 'inline',
};

const KEY = Symbol('templateFieldConfigFormContext');

export const getTemplateFieldConfigFormContext = <T extends TemplateFieldConfig['type'] = TemplateFieldConfig['type']>() => getContext<TemplateFieldConfigFormContext<T>>(KEY);

export const setTemplateFieldConfigFormContext = (type: TemplateFieldConfig['type'], initialFormValues?: FieldFormValue) =>
    setContext<TemplateFieldConfigFormContext<TemplateFieldConfig['type']>>(
        KEY,
        createTemplateFieldConfigFormContext(initialFormValues ? cloneDeep(initialFormValues) : cloneDeep(initialFormValuesByFieldType[type])),
    );

const textFieldInitialFormValues: FieldFormValue<'text'> = {
    ...baseFieldInitialFormValues,
    type: 'text',
    placeholder: '',
};

const textareaFieldInitialFormValues: FieldFormValue<'textarea'> = {
    ...baseFieldInitialFormValues,
    type: 'textarea',
    placeholder: '',
};

const numberFieldInitialFormValues: FieldFormValue<'number'> = {
    ...baseFieldInitialFormValues,
    defaultValue: undefined,
    type: 'number',
    unit: undefined,
    placeholder: '',
};
const priceFieldInitialFormValues: FieldFormValue<'price'> = {
    ...baseFieldInitialFormValues,
    defaultValue: undefined,
    type: 'price',
    unit: undefined,
    placeholder: '',
};
const dateFieldInitialFormValues: FieldFormValue<'date'> = {
    ...baseFieldInitialFormValues,
    type: 'date',
    placeholder: '',
    dateFormat: '',
    defaultValue: '',
};
const radioFieldInitialFormValues: FieldFormValue<'radio'> = {
    ...baseFieldInitialFormValues,
    type: 'radio',
    options: [
        { label: '', id: generateId() },
        { label: '', id: generateId() },
    ],
};
const checkFieldInitialFormValues: FieldFormValue<'check'> = {
    ...baseFieldInitialFormValues,
    type: 'check',
    options: [
        { label: '', id: generateId() },
        { label: '', id: generateId() },
    ],
};

const initialFormValuesByFieldType: Record<TemplateFieldConfig['type'], FieldFormValue<TemplateFieldConfig['type']>> = {
    text: textFieldInitialFormValues,
    textarea: textareaFieldInitialFormValues,
    number: numberFieldInitialFormValues,
    price: priceFieldInitialFormValues,
    date: dateFieldInitialFormValues,
    radio: radioFieldInitialFormValues,
    check: checkFieldInitialFormValues,
};
